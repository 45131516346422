

.form {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 10px;
    padding-inline: 2em;
    padding-bottom: 2em;
    margin-bottom: 2em;
    background-color: transparent;
    border-radius: 20px;
    margin-top: 12.5vh;
    margin-bottom: 17.5vh;
}
  
.form-heading {
    text-align: center;
    margin: 1em 2em;
    color: white;
    font-size: 1.2em;
    background-color: transparent;
    align-self: center;
    font-weight: bold; 
    font-size: xx-large;
}
  
.form-field {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    border-radius: 10px;
    padding: 0.6em;
    border: none;
    outline: none;
    color: white;
    background-image: linear-gradient(90deg, #f19af3, #f099b5);
    border: 2px solid;
}
  
.input-field {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    color: white;
    padding-inline: 1em;
}
  
.sendMessage-btn {
    cursor: pointer;
    margin-bottom: 3em;
    padding: 1em;
    border-radius: 10px;
    border: 0.5px solid;
    outline: none;
    background-image: linear-gradient(180deg, #a6e1ff, #74c6ff);
    color: #000;
    font-weight: bold;
    transition: all ease-in-out 0.3s;
}
  
.sendMessage-btn:hover {
    transition: all ease-in-out 0.3s;
    background-color: #a6e1ff;
    color: white;
    cursor: pointer;
    box-shadow: inset 2px 5px 10px rgba(5, 5, 5, 0.5), 0 0 15px 5px rgba(166, 225, 255, 0.5);
}
  
.form-card1 {
    margin-top: 15vh;
    background-image: linear-gradient(90deg, #f19af3, #f099b5);
    border-radius: 22px;
    transition: all 0.3s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px,
                rgba(245, 73, 144, 0.5) 5px 10px 15px;
}
  
.form-card1:hover {
    box-shadow: rgba(0, 0, 0, 0.45) 0px 8px 20px,
                rgba(245, 73, 144, 0.6) 7px 15px 20px;
    transform: scale(1.02);
}
  
.form-card2 {
    border-radius: 0;
    transition: all 0.2s;
}
  
.form-card2:hover {
    transform: scale(1.02);
    border-radius: 20px;
    box-shadow: 0px 0px 30px 1px rgba(241, 154, 243, 0.3);
}

.dark-mode .form-heading{
    color: black;
}
  
.dark-mode .form-card1 {
    background-image: linear-gradient(180deg, #a6e1ff, #74c6ff); 
    box-shadow: 0 0 10px rgba(102, 181, 255, 0.7), 0 0 20px rgba(102, 181, 255, 0.5), 0 0 30px rgba(102, 181, 255, 0.3); 
}
  
.dark-mode .form-card1:hover {
    box-shadow: 0 0 25px rgba(102, 181, 255, 1), 0 0 50px rgba(102, 181, 255, 0.9), 0 0 75px rgba(102, 181, 255, 0.8); 
}
  
.dark-mode .form-field {
    background-image: linear-gradient(90deg, #a6e1ff, #74c6ff); 
    border: 2px solid;
    border-color: linear-gradient(90deg, #f19af3, #f099b5);
}
  
.dark-mode .sendMessage-btn {
    background-image: linear-gradient(90deg, #f19af3, #f099b5); 
    color: white;
}
  
.dark-mode .sendMessage-btn:hover {
    background-color: #f19af3;
    color: white;
    box-shadow: inset 2px 5px 10px rgba(5, 5, 5, 0.5), 0 0 15px 5px rgba(245, 73, 144, 0.5);
}
