
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;  
    overflow-y: auto;    
}

.Body {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  z-index: 100; 
  overscroll-behavior: none; 
  background-attachment: scroll;

}

.Navigation {
  display: flex;
  justify-content: center;
  margin-top: 0px; 
  position: sticky;
  top: 0px; 
  z-index: 1000;
  padding: 20px;
  
}

body {
	background: linear-gradient(180deg, #add8e6, #87cefa);
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	background-attachment: fixed;
	top: 0;
}

body.dark-mode {
	background: linear-gradient(180deg, #1b1b1b, #222222); 
	background-attachment: fixed;
  }

#background-wrap {
  bottom: 0;
	left: 0;
	padding-top: 50px;
	position: fixed;
	right: 0;
	top: 150px;
	z-index: -1;
}

@-webkit-keyframes animateCloud {
    0% {
        margin-left: -1000px;
    }
    100% {
        margin-left: 100%;
    }
}

@-moz-keyframes animateCloud {
    0% {
        margin-left: -1000px;
    }
    100% {
        margin-left: 100%;
    }
}

@keyframes animateCloud {
    0% {
        margin-left: -1000px;
    }
    100% {
        margin-left: 100%;
    }
}


.x1 {
	-webkit-animation: animateCloud 35s linear infinite;
	-moz-animation: animateCloud 35s linear infinite;
	animation: animateCloud 35s linear infinite;
	
	-webkit-transform: scale(0.65);
	-moz-transform: scale(0.65);
	transform: scale(0.65);
}

.x2 {
	-webkit-animation: animateCloud 20s linear infinite;
	-moz-animation: animateCloud 20s linear infinite;
	animation: animateCloud 20s linear infinite;
	
	-webkit-transform: scale(0.3);
	-moz-transform: scale(0.3);
	transform: scale(0.3);
}

.x3 {
	-webkit-animation: animateCloud 30s linear infinite;
	-moz-animation: animateCloud 30s linear infinite;
	animation: animateCloud 30s linear infinite;
	
	-webkit-transform: scale(0.5);
	-moz-transform: scale(0.5);
	transform: scale(0.5);
}

.x4 {
	-webkit-animation: animateCloud 18s linear infinite;
	-moz-animation: animateCloud 18s linear infinite;
	animation: animateCloud 18s linear infinite;
	
	-webkit-transform: scale(0.4);
	-moz-transform: scale(0.4);
	transform: scale(0.4);
}

.x5 {
	-webkit-animation: animateCloud 25s linear infinite;
	-moz-animation: animateCloud 25s linear infinite;
	animation: animateCloud 25s linear infinite;
	
	-webkit-transform: scale(0.55);
	-moz-transform: scale(0.55);
	transform: scale(0.55);
}


.cloud {
	background: #fff;
	background: -moz-linear-gradient(top,  #fff 5%, #f1f1f1 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(5%,#fff), color-stop(100%,#f1f1f1));
	background: -webkit-linear-gradient(top,  #fff 5%,#f1f1f1 100%);
	background: -o-linear-gradient(top,  #fff 5%,#f1f1f1 100%);
	background: -ms-linear-gradient(top,  #fff 5%,#f1f1f1 100%);
	background: linear-gradient(top,  #fff 5%,#f1f1f1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f1f1f1',GradientType=0 );
	
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	border-radius: 100px;
	
	-webkit-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
	box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);

	height: 120px;
	position: relative;
	width: 350px;
}

.cloud:after, .cloud:before {
  background: #fff;
	content: '';
	position: absolute;
	z-indeX: -1;
}

.cloud:after {
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	border-radius: 100px;

	height: 100px;
	left: 50px;
	top: -50px;
	width: 100px;
}

.cloud:before {
	-webkit-border-radius: 200px;
	-moz-border-radius: 200px;
	border-radius: 200px;

	width: 180px;
	height: 180px;
	right: 50px;
	top: -90px;
}

body.dark-mode .cloud {
	background: #7a7a7a; 
	background: -moz-linear-gradient(top, #7a7a7a 5%, #666666 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #7a7a7a), color-stop(100%, #666666));
	background: -webkit-linear-gradient(top, #7a7a7a 5%, #666666 100%);
	background: -o-linear-gradient(top, #7a7a7a 5%, #666666 100%);
	background: -ms-linear-gradient(top, #7a7a7a 5%, #666666 100%);
	background: linear-gradient(top, #7a7a7a 5%, #666666 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7a7a7a', endColorstr='#666666', GradientType=0);
  }
  
  body.dark-mode .cloud:after, body.dark-mode .cloud:before {
	background: #7a7a7a; 
	overscroll-behavior: none; 

  }

  .welcomeMessageDiv{
	width: 50vw;
	margin-top: 10vh;
  }

  .aboutMeDiv{
	width: 50vw;
  }

  .timelinediv { 
	margin-top: 10vh;
  }

  .fade {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
  }
  
  .fade-visible {
	opacity: 1;
  }

  .projectsDiv {
	margin-top: 10vh;
  }
  
  