/* Center the navbar container */
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  position: relative;
}

/* Logo container styles */
.logo-container {
  position: absolute;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.logo {
  width: 100px; /* Adjust size as needed */
  height: auto;
  transition: transform 0.2s ease-in-out;
}

.logo:hover {
  animation: jiggle 0.5s infinite;
}

/* Jiggle animation */
@keyframes jiggle {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

.button-container {
  display: flex;
  background: linear-gradient(90deg, #f19af3, #f099b5);
  width: 400px;
  height: 50px;
  align-items: center;
  justify-content: space-around;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px,
              rgba(245, 73, 144, 0.5) 5px 10px 15px;
  transition: all ease-in-out 0.3s;
}

.button {
  outline: 0 !important;
  border: 0 !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.button-container:hover {
  transform: scale(1.10);
  box-shadow: rgba(0, 0, 0, 0.45) 0px 8px 20px,
              rgba(245, 73, 144, 0.6) 7px 15px 20px;
}

.button:hover {
  transform: scale(1.30);
}

.icon {
  font-size: 25px;
}

.theme-switch-container {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
