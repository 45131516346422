@keyframes slideInFromBottom {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .welcome-message {
    font-family: 'Freeman', sans-serif;
    color: black;
    text-align: center;
    margin: 20px;
  }

  .dark-mode .welcome-message {
    color: white;
  }
  
  .welcome-message h1 {
    font-size: 4em;
    font-weight: bold;
    margin-bottom: 10px;
    animation: slideInFromBottom 1s ease-out;
  }
  
  .welcome-message p {
    font-size: 1.5em;
    animation: slideInFromBottom 1s ease-out;
    animation-delay: 0.5s; 
    opacity: 0; 
    animation-fill-mode: forwards;
  }
  
  .button-container-horizontal {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
    animation: slideInFromBottom 1s ease-out;
    animation-delay: 1s; 
    opacity: 0; 
    animation-fill-mode: forwards; 
  }
  
  .projects-button,
  .resume-button,
  .github-button,
  .linkedin-button {
    transition: all ease-in-out 0.2s;

    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 25px;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #f19af3, #f099b5);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2.5px 7.5px,
      rgba(245, 73, 144, 0.5) 2.5px 5px 7.5px;
  }

  
  .projects-button,
  .resume-button {
    color: white; 
    font-family: 'Freeman', sans-serif;
    font-size: large;
  }

  
  .projects-button .icon,
  .resume-button .icon {
    width: 1em;
    height: 1em;
    fill: white;
    margin-left: 10px; 
  }
  
  .github-button .icon,
  .linkedin-button .icon {
    width: 1em;
    height: 1em;
    fill: white;
  }

  .projects-button:hover,
  .resume-button:hover,
  .github-button:hover,
  .linkedin-button:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.45) 0px 4px 10px,
                rgba(245, 73, 144, 0.6) 3.5px 7.5px 10px;
  }




  