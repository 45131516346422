.about-me {
  font-family: 'Freeman', sans-serif;
  color: black; 
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 20px;
  margin-top: 25vh;
}

.dark-mode .about-me {
  color: white; 
}

.about-me h1 {
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 10px;
  animation: slideInFromBottom 1s ease-out;
  animation-fill-mode: forwards;
  animation-delay: 1.25s; 
  opacity: 0; 
}

.about-me p {
  font-size: 1.5em;
  animation: slideInFromBottom 1s ease-out;
  animation-delay: 1.5s; 
  opacity: 0; 
  animation-fill-mode: forwards; 
}

.about-me p b {
  color: #E75480; 
}

.dark-mode .about-me p b {
  color: #f099b5;
}

.projects-link {
  color: #E75480; 
  text-decoration: underline; 
  text-decoration-color: #E75480; 
}

.dark-mode .projects-link {
  color: #f099b5;
  text-decoration: underline; 
  text-decoration-color: #f099b5; 
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
